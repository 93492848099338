import {observer, inject} from 'mobx-react';

/**
 * Компонент высшего порядка. Принимает компонент и настраивает его под склад данных
 * Держит в себе логику подключения склада.
 * inject возврощает фун-цию, которую мы вызвать и передать туда observer(Component)
 * @param Component
 * @returns {((React.StatelessComponent<P> & IReactComponent extends IReactComponent<P> ? IWrappedComponent<P> : never) | (React.ComponentClass<P> & IReactComponent extends IReactComponent<P> ? IWrappedComponent<P> : never) | (React.ClassicComponentClass<P> & IReactComponent extends IReactComponent<P> ? IWrappedComponent<P> : never)) | ((React.StatelessComponent<P> & IWrappedComponent<any>) | (React.ComponentClass<P> & IWrappedComponent<any>) | (React.ClassicComponentClass<P> & IWrappedComponent<any>))}
 */
export default function (Component) {
    return inject('stores')(observer(Component));
}
